import React from "react";
import styled from "styled-components";

import { getFooterRoutes } from "../../routes.js";
import {  devices } from "../common/Styles.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .5rem;
`;

const StyledMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  z-index: 1;

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.3s;
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const Icon = styled.img`
  width: 24px;

  @media ${devices.md} {
    width: 32px;
  }
`;

const Footer = () => (
  <Container id="footer">
    <StyledMenu>
      {getFooterRoutes().map((r) => (
        <a href={r.menuPath} key={r.menuPath} target={r.target}>
          <Icon src={r.icon} alt={r.name} />
        </a>
      ))}
    </StyledMenu>
  </Container>
);

export default Footer;