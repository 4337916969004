import React from "react";
import { StoreContextProvider } from "./contexts/StoreContext.js";
import useStore from "./hooks/useStore.js";


const Providers = ({ children }) => {
  return (
    <StoreContextProvider>
     {children}
    </StoreContextProvider>
  );
};

export default Providers;
