import React from "react";
import styled from "styled-components";

import { FullScreenHeight, Title, devices } from "../components/common/Styles";
import Connect from "../components/web3/Connect.js";

const Content = styled.div`
  /* background-color: #e4edf6; */
  z-index: 9;
  padding:1.5rem 0;
`;

const P = styled.p`
  font-size: 1.25rem;
  z-index: 1;
  max-width: 420px;
  font-weight:bold;
  color:#630071;


  filter:drop-shadow(2px 2px 1px #ffdcf3);


`;

const DonJuan = styled.img`
  margin-right: -1.5rem;

  @media ${devices.md} {
    max-width: 420px;
    position: fixed;
    bottom: -100px;
    right: 0;
    z-index: 0;
  }
`;

const Bio = () => {
  return (
    <>
      <FullScreenHeight>
        <Title>BIO</Title>
        <Content>
          <P>
            My name is Adonis Girault, I go by Don Juan. I’ve been making music
            since 2019, and after years of building I’m finally ready to start
            presenting my art to the world.
          </P>
          <P>
            I’ve been deeply involved in WEB3 since Fall 2020, and it is another
            one of my dreams to establish myself as a monumentally influential
            figure in Web3, the metaverse & cryptocurrency. As a result, I am
            dropping an NFT alongside all of my singles moving forward.
          </P>
          <P>
          This upcoming project will be (insert here) my Genesis Collection to celebrate the release of my 2022 debut single “Moon / Wings”. 
          </P>
        </Content>

        <DonJuan src="./images/donjuan.jpg" />
      </FullScreenHeight>
    </>
  );
};

export default Bio;
