import multicallAbi from "../abis/multicall.json";
import erc20Abi from "../abis/erc20.json";
import erc721aAbi from "../abis/erc721a.json";
import fffAbi from "../abis/fff.json";



const contracts = {
 
  FFF: {
    abi: fffAbi,
    address: {
      1: "0x4721d66937b16274fac603509e9d61c5372ff220",
      4: "0x4721d66937b16274fac603509e9d61c5372ff220",
      1337:"",
    },
  },

};

export default contracts;
