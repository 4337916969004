const constants = {
  appTitle: "DON JUAN - MOON / WINGS",
  mobileBreakPoint: 880,

  serverUrl: process.env.REACT_APP_SERVER_URL,
  chainId: process.env.REACT_APP_CHAIN_ID,

  infuraUrl:{
    1:`wss://mainnet.infura.io/ws/v3/${process.env.REACT_APP_INFURA_ID}`,
    4:`wss://rinkeby.infura.io/ws/v3/${process.env.REACT_APP_INFURA_ID}`,
    1337:"http://127.0.0.1:8545/"
  },

  etherscanUrl:{
    1:`https://etherscan.io/address/`,
    4:`https://rinkeby.etherscan.io/address/`,
  }

};
export default constants;
