import { UiStore } from "./uiStore.js";
import { Web3Store } from "./web3Store.js";


export class RootStore {
  constructor() {
    this.uiStore = new UiStore({ rs: this });
    this.web3Store = new Web3Store({ rs: this });

  }
}
