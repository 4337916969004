import React from "react";
import styled from "styled-components";

import { FullScreenHeight, Title, devices } from "../components/common/Styles";
import Connect from "../components/web3/Connect.js";
import { observer } from "mobx-react-lite";
import useStore from "../hooks/useStore";

const MintImage = styled.img`
  width: 100%;
  max-width: 280px;
  height: auto;
  margin: 1.5rem 0;
  /* margin: auto; */

  @media ${devices.md} {
    max-width: 420px;
  }
`;

const Button = styled.button`
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1.5rem;

  display: flex;
  background-color: transparent;
  border: solid 2px;
  padding: 0.5rem 1rem;

  color: #630071;
  &:hover {
    color: #ffdcf3;
    background-color: #630071;
  }
  /* ffdcf3 */
`;

const MintInfos = styled.div`
  font-size: 1.15rem;
  font-weight: bold;
`;

const MintCounter = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const Mint = () => {
  const { web3Store } = useStore();
  return (
    <>
      <FullScreenHeight>
        <Title>MINT</Title>
        <MintImage src="./images/moon-cover.png" />
        <MintCounter>???/111</MintCounter>
        <MintInfos>
          PRICE : TBD <br />
        </MintInfos>

        <Connect></Connect>
        {/* <div>{web3Store.connected && <Button>MINT</Button>}</div>
        <br />
        {!web3Store.connected && <Connect></Connect>} */}
      </FullScreenHeight>
    </>
  );
};

export default observer(Mint);
