import React, { useEffect } from "react";
import Menu from "./Menu.js";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import useStore from "../../hooks/useStore.js";
import { observer } from "mobx-react-lite";
import Footer from "./Footer.js";
import Social from "./Social.js";
import Intro from "./Intro.js";
import { NavLink } from "react-router-dom";

import { useLocation } from "react-router";

import GlobalStyle from "./GlobalStyles.js";
import Hamburger from "hamburger-react";
import { devices } from "../common/Styles.js";

const Container = styled.div`
  /* display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh; */
  background-color: #e0e7f1;
  background-color: #e4edf6;
  transition: background-color color 0.1s;
`;

const StyledLayout = styled.div`
  /* display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; */
`;

const HamburgerContainer = styled.div`
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 99;

  @media ${devices.md} {
    display: none;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  box-sizing: border-box;

  padding-top: 220px;
  @media ${devices.md} {
    padding-top: 0rem;
    margin-left: 47vw;
  }
`;

const DonJuan = styled.img`
  position: absolute;

  object-fit: cover;
  z-index: 0;

  left: 0;
  top: 0;
  width: auto;
  height: 300px;

  @media ${devices.md} {
    position: fixed;

    top: 0;
    left: 0;
    width: 45vw;
    height: 100%;
    max-height: none;
  }
`;

const DonJuanText = styled.div`
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  z-index: 99;

  a {
    text-decoration: none;
  }
`;

const Layout = ({ children }) => {
  const { uiStore } = useStore();

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <Container>
      <GlobalStyle />

      <Helmet>
        <title>{uiStore.appTitle}</title>
      </Helmet>
      <StyledLayout>
        <HamburgerContainer>
          <Hamburger
            toggled={uiStore.isMenuOpen}
            toggle={() => uiStore.toggleMenu()}
          />
        </HamburgerContainer>
        <Intro isOpen={uiStore.isIntroOpen} />

        <DonJuan src="./images/donjuan2.jpg" />
        <DonJuanText>
          <NavLink to={{ pathname: "/" }} onClick={() => uiStore.toggleIntro()}>
            DON JUAN
          </NavLink>
        </DonJuanText>
        <Menu isOpen={uiStore.isMenuOpen} />
        <ContentContainer>{children}</ContentContainer>
        <Social></Social>
        {/* <Footer></Footer> */}
      </StyledLayout>
    </Container>
  );
};

export default observer(Layout);
