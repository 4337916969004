import "./App.css";

import Providers from "./Providers";
import { Layout } from "./components/layout";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import routes from "./routes";
import { createBrowserHistory } from "history";

export const getBasePath = () => {
  try {
   
    const url = new URL(process.env.PUBLIC_URL);
    return url.pathname;
  } catch (e) {
    return process.env.PUBLIC_URL;
  }
};

export const history = createBrowserHistory({
  basename: getBasePath(),
});

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
}

function App() {
  return (
    <Providers>
      <Router basename={getBasePath()} history={history}>
        <Layout>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </Layout>
      </Router>
    </Providers>
  );
}

export default App;
