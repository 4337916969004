import React from "react";
import { FullScreenHeight, Title } from "../components/common/Styles";

import useStore from "../hooks/useStore";

import { observer } from "mobx-react-lite";

import styled from "styled-components";

const Logo = styled.img`
  max-width: 320px;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
`;

const Home = () => {
  const { web3Store } = useStore();

  return (
    <>
      <FullScreenHeight id="home">
        <Container>
          <Logo src="./images/intro-logo.png" />
        </Container>
      </FullScreenHeight>
    </>
  );
};

export default observer(Home);
