import { HomePage, MintPage, FaqPage,BioPage, Error404Page, ContactPage } from "./pages";


const routes = [
  {
    name: "Home",
    path: `/`,
    component: HomePage,
    exact: true,
  },
  {
    name: "Mint",
    path: `/mint`,
    component: MintPage,
    exact: true,
  },
  {
    name: "Bio",
    path: `/bio`,
    component: BioPage,
    exact: true,
  },
  {
    name: "Faq",
    path: `/faq`,
    component: FaqPage,
    exact: true,
  },
  {
    name: "Contact",
    path: `/contact`,
    component: ContactPage,
    exact: true,
  },
  {
    name: "404",
    path: `*`,
    component:Error404Page,
  },

];

const menuRoutes = [
  // {
  //   name: "Home",
  //   menuPath: `/`,
  //   menuHash: `#home`,
  // },
  
  {
    name: "Bio",
    menuPath: `/bio`,
  },
  {
    name: "Mint",
    menuPath: `/mint`,
  },
  {
    name: "Faq",
    menuPath: `/faq`,
  },

  {
    name: "Contact",
    menuPath: `/contact`,
  },

  
 
];

const footerRoutes = [
  {
    name: "Etherscan",
    menuPath:
      "https://etherscan.io/address/",
    target: "_blank",
    icon: "./icons/etherscan.svg",
  },
  {
    name: "Discord",
    menuPath: "https://discord.gg/",
    target: "_blank",
    icon: "./icons/discord.svg",
  },
  {
    name: "Twitter",
    menuPath: "https://twitter.com/",
    target: "_blank",
    icon: "./icons/twitter.svg",
  },
  {
    name: "Opensea",
    menuPath: "https://opensea.io/collection/",
    target: "_blank",
    icon: "./icons/opensea.svg",
  },
];


const socialRoutes = [
  {
    url:'https://instagram.com/youcancallhimjuan'
  },
  {
    url:'https://twitter.com/mysticrendition'
  },
  {
    url:'https://www.youtube.com/channel/UCLeBdQC-EaVwi3wofkRTqlw'
  },
  {
    url:'https://soundcloud.com/youcancallhimjuan'
  },
  {
    url:'https://tiktok.com/@youcancallhimjuan'
  },
  {
    url:'https://open.spotify.com/artist/4lRyLnPu0Xm9Dh7kAiklwy?si=rWINDx8_RwOGuAamclpj3w'
  },
  {
    url:'https://music.apple.com/us/artist/don-juan/1492018886'
  },
  {
    url:'mailto:adonisgirault@tapedelayfilms.com'
  },

]

export default routes;

export const getMenuRoutes = () => {
  return menuRoutes.filter((r) => r.menuPath);
};

export const getFooterRoutes = () => {
  return footerRoutes.filter((r) => r.menuPath);
};


export const getSocialRoutes = () => {
  return socialRoutes;
};
