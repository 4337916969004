import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import useStore from '../../hooks/useStore'
import { observer } from "mobx-react-lite";

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index:9999;
  background-color: #000000;

  pointer-events:${({isOpen}) => isOpen ? 'all' : 'none'};

  transition:opacity 1s;
  opacity:${({isOpen}) => isOpen ? '1' : '0'};

  video {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

`;

const Logo = styled.img `
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  width: 320px;
  height: auto;
  object-fit: cover;
  z-index: 999;
  transition:opacity 3s;
  transform: translateX(-50%) translateY(-50%);
  opacity:${({isVisible}) => isVisible ? '1' : '0'};
`

const Intro = ({isOpen}) => {
  const {uiStore} = useStore()
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 1500);
  }, []);

  return (
    <Container id="intro" isOpen={isOpen}>
      <video src="./images/intro.m4v" autoPlay muted loop />
      <Logo src="./images/intro-logo.png" isVisible={isVisible} onClick={() => uiStore.toggleIntro()}/>
    </Container>
  );
};

export default observer(Intro);
