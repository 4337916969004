import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { getMenuRoutes } from "../../routes.js";
import { devices } from "../common/Styles.js";
import useStore from "../../hooks/useStore.js";
import { observer } from "mobx-react-lite";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9;
`;

// const SelectorContainer = styled.div`
//   position: absolute;
//   top: 0.5rem;
//   left: 0.5rem;
//   z-index: 99;
// `;

// const BurgerContainer = styled.div`
//   position: absolute;
//   top: 0.75rem;
//   right: 1rem;
//   z-index: 99;
// `;

const StyledMenu = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  /* height:100vh; */

  font-size: 1.5rem;
  font-weight: bold;
  /* background-color: salmon; */

  box-sizing: border-box;
  filter:drop-shadow(2px 2px 1px #ffdcf3);


  padding: 1rem;
  padding-top: 4.5rem;

  transform: ${({ isOpen }) =>
    isOpen ? "translateY(0%)" : "translateY(-100%)"};
  transition: transform 0.3s;

  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  @media ${devices.md} {
    /* border-bottom: none; */
    top: 0;
    left: 0;

    width: 100%;

    transform: translateX(0) translateY(0);

    padding: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    /* background-color: transparent; */

    position: relative;
    flex-direction: row;
    justify-content: flex-end;

    width: 100%;
    height: auto;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;

    // padding: 0.5rem 0;
    margin: 0 1rem;
  }
`;

const MenuItems = () => {
  const { uiStore } = useStore();
  return (
    <>
      {getMenuRoutes().map((r) => {
        return (
          <Link
            to={{ pathname: r.menuPath, hash: r.menuHash }}
            key={`${r.menuPath}${r.menuHash}`}
            onClick={() => {
              uiStore.toggleMenu();
            }}
          >
            {r.name}
          </Link>
        );
      })}
    </>
  );
};

const Menu = ({ isOpen }) => {
  const { uiStore } = useStore();

  return (
    <Container>
      <StyledMenu isOpen={isOpen}>
        <MenuItems />
      </StyledMenu>
    </Container>
  );
};

export default observer(Menu);
