import React from "react";
import styled from "styled-components";

import { getSocialRoutes } from "../../routes.js";
import {  devices } from "../common/Styles.js";
import { SocialIcon } from 'react-social-icons';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: .5rem;

  position:fixed;
  bottom:0;
  right:0;

`;

const StyledMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  z-index: 1;

  /* a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;

    transition: all 0.3s;
    &:hover {
      transform: scale(1.05);
    }
  } */


  a.social-icon {
    margin: 0;
    width: 40px !important;
    height: 40px !important;

  }

`;

const Icon = styled.img`
  width: 24px;

  @media ${devices.md} {
    width: 32px;
  }
`;

const Footer = () => (
  <Container id="socials">
    <StyledMenu>
      {getSocialRoutes().map((r) => (
        <SocialIcon url={r.url} key={r.url} fgColor="#000" bgColor="transparent" target="_blank" >    
        </SocialIcon>
      ))}
    </StyledMenu>
  </Container>
);

export default Footer;