import React from "react";
import styled from "styled-components";

import useStore from "../../hooks/useStore";
import { observer } from "mobx-react-lite";

const Button = styled.button`
  cursor: pointer;
  padding:0.5rem 1rem;
  /* border-radius:1rem; */
  background-color:transparent;
  border:solid 2px;
  font-family:'PT Sans';
  text-transform:uppercase;
  font-size:1.15rem;
  font-weight:bold;

  color: #630071;
  &:hover {
    color: #ffdcf3;
    background-color: #630071;
  }
`;

const EthSign = styled.span`
  position: relative;
  width: 0.75rem;
  margin: 0 0.25rem;
  height: 0.5rem;
  display: inline-block;

  &:before {
    position: absolute;
    font-family: "Arial";
    font-size: 1rem;
    content: "⧫";
    bottom: -0.4rem;
    left: 0;
  }
`;

const Connect = () => {
  const { web3Store } = useStore();

  async function connect() {
    try {
      await web3Store.connect();
    } catch (ex) {
      console.log(ex);
    }
  }

  async function disconnect() {
    try {
      await web3Store.disconnect();
    } catch (ex) {
      console.log(ex);
    }
  }

  const onClick = () => {
    if (web3Store.connected) {
      disconnect();
    } else {
      connect();
    }
  };

  return (
    <div>
      <Button onClick={onClick}>
        {web3Store.connected ? (
          <b>
            {web3Store.addressFormatted}
            {web3Store.balanceFormatted && (
              <>
                <EthSign />
                {web3Store.balanceFormatted}
              </>
            )}{" "}
          </b>
        ) : (
          <span> Connect Wallet</span>
        )}
      </Button>
    </div>
  );
};

export default observer(Connect);
