import React from "react";
import styled from "styled-components";

import {  FullScreenHeight, Title } from "../components/common/Styles";


const Error404 = () => {
  return (
    <>
      <FullScreenHeight   >
        <Title> 404 </Title>
      </FullScreenHeight>
    </>
  );
};

export default Error404;
