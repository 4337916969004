import React from "react";
import { createContext } from "react";
import { RootStore } from "../store/rootStore";

const Store = new RootStore();
const StoreContext = createContext();

const StoreContextProvider = ({ children }) => {
  return (
    <StoreContext.Provider value={Store}>{children}</StoreContext.Provider>
  );
};

export { Store, StoreContext, StoreContextProvider };
