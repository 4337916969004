import React from "react";
import styled from "styled-components";

export const breakpoints = {
  md: 880,
  lg: 1280,
};

export const devices = {
  md: `(min-width: ${breakpoints.md}px)`,
  lg: `(min-width: ${breakpoints.lg}px)`,
};

export const Title = styled.h2`
  font-size: 2.25rem;
  margin-top: 0;
  margin-bottom: 0;

  filter:drop-shadow(2px 2px 1px #ffdcf3);

  color: #fff;
  z-index: 99;

  @media ${devices.md} {
    font-size: 3rem;
    position: fixed;
    margin-left: -280px;
    /* margin-top: 1.5rem; */
  }
`;  

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const FullScreenHeight = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 1.5rem;
  box-sizing: border-box;

  @media ${devices.md} {
    padding-top: 4.5rem;
  }
`;
