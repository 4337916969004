import React from "react";
import styled from "styled-components";

import { FullScreenHeight, Title } from "../components/common/Styles";


const Contact = () => {
  return (
    <>
      <FullScreenHeight>
      <Title>CONTACT</Title>
      </FullScreenHeight>
    </>
  );
};

export default Contact;
