import React from "react";
import styled from "styled-components";

import { FullScreenHeight, Title } from "../components/common/Styles";
import Connect from "../components/web3/Connect.js";

const Question = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 1.5rem;
  filter:drop-shadow(2px 2px 1px #ffdcf3);

`;

const P = styled.p`
  font-size: 1.15rem;
  max-width: 640px;
`;

const Faq = () => {
  return (
    <>
      <FullScreenHeight>
        <Title>FAQ</Title>

        <Question>Question 1</Question>
        <P>
          Look, just because I don't be givin' no man a foot massage don't make
          it right for Marsellus to throw Antwone into a glass motherfuckin'
          house, fuckin' up the way the nigger talks. Motherfucker do that shit
          to me, he better paralyze my ass, 'cause I'll kill the motherfucker,
          know what I'm sayin'?
        </P>

        <Question>Question 2</Question>
        <P>
          Now that we know who you are, I know who I am. I'm not a mistake! It
          all makes sense! In a comic, you know how you can tell who the
          arch-villain's going to be? He's the exact opposite of the hero. And
          most times they're friends, like you and me! I should've known way
          back when... You know why, David? Because of the kids. They called me
          Mr Glass.
        </P>

        <Question>Question 3</Question>
        <P>
          Do you see any Teletubbies in here? Do you see a slender plastic tag
          clipped to my shirt with my name printed on it? Do you see a little
          Asian child with a blank expression on his face sitting outside on a
          mechanical helicopter that shakes when you put quarters in it? No?
          Well, that's what you see at a toy store. And you must think you're in
          a toy store, because you're here shopping for an infant named Jeb.
        </P>

        <Question>Question 4</Question>
        <P>
          My money's in that office, right? If she start giving me some bullshit
          about it ain't there, and we got to go someplace else and get it, I'm
          gonna shoot you in the head then and there. Then I'm gonna shoot that
          bitch in the kneecaps, find out where my goddamn money is. She gonna
          tell me too. Hey, look at me when I'm talking to you, motherfucker.
          You listen: we go in there, and that nigga Winston or anybody else is
          in there, you the first motherfucker to get shot. You understand?
        </P>

        <Question>Question 5</Question>
        <P>
          The path of the righteous man is beset on all sides by the iniquities
          of the selfish and the tyranny of evil men. Blessed is he who, in the
          name of charity and good will, shepherds the weak through the valley
          of darkness, for he is truly his brother's keeper and the finder of
          lost children. And I will strike down upon thee with great vengeance
          and furious anger those who would attempt to poison and destroy My
          brothers. And you will know My name is the Lord when I lay My
          vengeance upon thee.
        </P>
      </FullScreenHeight>
    </>
  );
};

export default Faq;
