import { makeAutoObservable } from "mobx";
import Config from "../config.js";

import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

import { ethers } from "ethers";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: Config.constants.infuraId // required
    }
  }
};

export class Web3Store {
  _address = undefined;
  _balance = undefined;

  web3Modal = undefined;
  provider = undefined;
  web3 = undefined;

  _blocknumber = undefined;
  _chainId = undefined;

  shouldReload = 0;

  constructor({ rs }) {
    this.rs = rs;
    makeAutoObservable(this, {});

    this.chainId = Config.constants.chainId;
  }

  async connect() {
    this.web3Modal = new Web3Modal({
      //network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });

    this.provider = await this.web3Modal.connect();
    this.web3 = new Web3(this.provider);

    this.registerProviderEvents();
    await this.registerWeb3Events();

    await this.updateChainId();
    await this.loadAccount();
  }

  async loadAccount() {
    if (!this.web3) return;
    const accounts = await this.web3.eth.getAccounts();
    this.address = accounts[0];
    this.balance = ethers.utils.formatEther(
      await this.web3.eth.getBalance(this.address)
    );
  }

  async disconnect() {
    if (this.newBlockHeadersSubscription) {
      await this.newBlockHeadersSubscription.unsubscribe();
    }

    if (
      this.web3 &&
      this.web3.currentProvider &&
      this.web3.currentProvider.close
    ) {
      await this.web3.currentProvider.close();
    }
    await this.web3Modal.clearCachedProvider();

    this.provider = undefined;
    this.web3 = undefined;
    this.address = undefined;
    this.chainId = Config.constants.chainId;
  }

  registerProviderEvents() {
    this.provider.on("connect", this.onConnect);
    this.provider.on("error", this.onError);
    this.provider.on("disconnect", this.onDisconnect);
    this.provider.on("chainChanged", this.onChainChanged);
    this.provider.on("accountsChanged", this.onAccountsChanged);
  }

  onError = e => {
    console.log("web3Store provider error : ", e);
  };

  onConnect = e => {
    //console.log("web3Store provider connect : ", e);
  };

  onDisconnect = async e => {
    //console.log("web3Store provider disconnect : ", e);
    await this.disconnect();
  };

  onChainChanged = async e => {
    // console.log("web3Store provider chainChanged : ", e);
    await this.loadAccount();
  };

  onAccountsChanged = async e => {
    //console.log("web3Store provider accountChanged : ", e);
    await this.loadAccount();
  };

  /**************************/

  async registerWeb3Events() {
    this.newBlockHeadersSubscription = this.web3.eth.subscribe(
      "newBlockHeaders"
    );
    this.newBlockHeadersSubscription.on("data", this.onNewBlockHeaders);
    await this.updateBlockNumber();
  }

  onNewBlockHeaders = async (block, error) => {
    //console.log("onNewBlockHeaders : ", block.number, error);
    this.blocknumber = block.number;
    await this.loadAccount();
  };

  async updateBlockNumber() {
    try {
      this.blocknumber = await this.web3.eth.getBlockNumber();
    } catch (e) {
      console.log(e);
    }
  }
  async updateChainId() {
    try {
      this.chainId = await this.web3.eth.getChainId();
    } catch (e) {
      console.log(e);
    }
  }

  /*********** getters *********/

  get connected() {
    return this.address !== undefined;
  }

  get addressFormatted() {
    return `0x....${this.address.substr(this.address.length - 4, 4)}`;
  }

  get balanceFormatted() {
    return Number(this.balance).toFixed(4);
  }

  get address() {
    return this._address;
  }

  get balance() {
    return this._balance;
  }

  get blocknumber() {
    return this._blocknumber;
  }

  get chainId() {
    return this._chainId;
  }

  /*********** setters *********/

  set address(value) {
    this._address = value;
  }

  set balance(value) {
    this._balance = value;
  }

  set blocknumber(value) {
    this._blocknumber = value;
  }

  set chainId(value) {
    this._chainId = parseInt(value);
  }

  /*******************************************/

  async getContract(contractName) {
    const contractConfig = Config.contracts[contractName];

    if (contractConfig.address[this.chainId] === "") {
      return false;
    }

    try {
      const web3Provider = new ethers.providers.Web3Provider(this.provider);
      const signer = await web3Provider.getSigner();

      const contract = new ethers.Contract(
        contractConfig.address[this.chainId],
        contractConfig.abi,
        signer
      );

      return contract;
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }

  /******************************************/

  forceReload() {
    this.shouldReload++;
  }

  /** PCS Multicall */
  // multicall = async ({ abi, address, calls }) => {
  //   try {

  //     const multi = new this.web3.eth.Contract(
  //       Config.contracts.multicall.abi,
  //       Config.contracts.multicall.address
  //     );

  //     const itf = new ethers.utils.Interface(abi);
  //     const calldata = calls.map((call) => [
  //       address,
  //       itf.encodeFunctionData(call.name, call.params),
  //     ]);
  //     const { returnData } = await multi.methods.aggregate(calldata).call();
  //     const res = returnData.map((call, i) =>
  //       itf.decodeFunctionResult(calls[i].name, call)
  //     );

  //     return res;
  //   } catch (e) {
  //     console.log("multicall error : ", e);
  //   }

  // };
}
