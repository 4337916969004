import { makeAutoObservable, observable } from "mobx";
import Config from "../config.js";

export class UiStore {
  appTitle = Config.constants.appTitle;

  windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  isMenuOpen = false;

  isIntroOpen =true
  // isIntroOpen = false;

  constructor({ rs }) {
    this.rs = rs;
    makeAutoObservable(this, { windowDimensions: observable.struct });
    window.onresize = () => {
      this.setWindowDimensions();
    };
  }

  setAppTitle = (appTitle) => {
    this.appTitle = appTitle;
  };

  setWindowDimensions = () => {
    this.windowDimensions = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };

  isMobile() {
    return (
      this.windowDimensions &&
      this.windowDimensions.width < Config.constants.mobileBreakPoint
    );
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  toggleIntro() {
    this.isIntroOpen = !this.isIntroOpen;
  }
}
